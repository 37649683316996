@import '../theme.scss';

.dropdownInput {
  button {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    padding: 1.5em 0.5em 0.5em 0.5em;
    outline: 0;
    border: none;
    border-radius: $border-radius;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: $color-light-grey;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    z-index: 2;
    width: 100%;
    text-align: left;

    .arrow {
      position: absolute;
      right: 7px;
      left: unset;

      svg {
        transition: rotate 0.2s ease-in-out;
      }
    }

    &:focus,
    &:focus-within {
      border-color: $color-blue;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      background-color: $color-white;
    }
  }

  .options {
    height: auto;
    max-height: 0;
    overflow: hidden;
    overflow-y: scroll;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    border-radius: 0 0 $border-radius $border-radius;
    position: absolute;
    width: 100%;
    bottom: $border-radius;
    translate: 0 100%;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $color-white;
      margin-top: 2 * $border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-medium-grey;
      border-radius: $border-radius;
    }

    ul {
      li {
        padding: 0 5px;
        cursor: pointer;

        p {
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0.02em;
          padding: 0.5em;
          transition: background 0.2s ease-in-out;
          border-radius: $border-radius;
        }

        &:hover {
          p {
            background-color: $color-light-grey;
          }
        }
      }
    }
  }

  &.initial {
    button {
      padding: 1em 0.5em;
    }

    .currentValue {
      color: $color-dark-grey;
    }
  }

  &.active {
    button {
      .arrow {
        svg {
          rotate: 180deg;
        }
      }
    }

    .options {
      max-height: 200px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      padding: 16px 0;
    }
  }

  &.invalid {
    button {
      border-color: $color-red;
    }
  }
}
